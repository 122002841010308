.mr-3 {
  margin-right: 3em;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.border-2px {
  border-width: 2px;
}

.outline-2px {
  outline-width: 2px;
}
.outline-success {
  outline: 2px solid #2eb85c;
}
