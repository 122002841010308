.unread {
  background-color: #eeeeee;
  font-weight: bold;

  .notification-title {
    font-weight: 700;
  }
}
.dropdown-item {
  white-space: normal; /* Pozwala na zawijanie tekstu */
  word-wrap: break-word; /* Starsze przeglądarki */
  overflow-wrap: break-word; /* Współczesny standard */
}

.scrolled-list {
  height: 80vh;
  overflow-y: scroll;
}
